import Txt from '../components/Txt'

import ContentWrapper from '../components/ContentWrapper'
import Bar from '../components/Bar'
import { Box } from '@mui/material'

const actuallyOrderFactor = 0.5
const numberOfOrdersPerUser = 3

const nUsersEntries = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
const nIterations = 30
const nSubOrderForCollectinOrder = 2

const getSim = () => {
	const averageMonthlyCollectionOrdersDict = {}
	for (const nUsers of nUsersEntries) {
		console.log('\nnUsers: ', nUsers)
		const monthlyTotalCollectionOrderResults = []
		for (let i = 0; i < nIterations; i++) {
			const countsPerDay: { [key: number]: number } = {}
			for (let user = 0; user < nUsers; user++) {
				const userOrderDays = Array.from({ length: numberOfOrdersPerUser }, () =>
					Math.random() < actuallyOrderFactor ? Math.floor(Math.random() * 30) : undefined
				)
				userOrderDays
					.filter((day, index, arr) => arr.indexOf(day) === index)
					.forEach((orderDay) => {
						if (orderDay === undefined) return
						if (countsPerDay[orderDay] === undefined) {
							countsPerDay[orderDay] = 1
						} else {
							countsPerDay[orderDay]++
						}
					})
			}
			const collectionOrdersPerDay = Object.values(countsPerDay).map((count) =>
				Math.floor(count / nSubOrderForCollectinOrder)
			)
			const monthlyTotalCollectionOrders = collectionOrdersPerDay.reduce((a, b) => a + b, 0)
			monthlyTotalCollectionOrderResults.push(monthlyTotalCollectionOrders)
		}
		const average = Math.floor(
			monthlyTotalCollectionOrderResults.reduce((a, b) => a + b, 0) /
				monthlyTotalCollectionOrderResults.length
		)
		averageMonthlyCollectionOrdersDict[nUsers] = average
	}
	return averageMonthlyCollectionOrdersDict
}

const sim = getSim()
console.log('sim: ', sim)

const data = Object.entries(sim).map(([users, average]) => ({
	users,
	average,
})) as { users: string; average: number }[]

console.log('data: ', data)

const SimulationView = (): JSX.Element => {
	return (
		<ContentWrapper>
			<Txt>SimulationView</Txt>
			<Box width="100%" height={500} display="flex" flexDirection="column" border="1px solid red">
				<Bar
					keys={['average']}
					data={data}
					indexBy={'users'}
					yLegend=""
					hasAxisLeft
					marginRightPx={100}
					marginBottomPx={40}
					marginLefPx={40}
					marginTopPx={40}
				/>
			</Box>
		</ContentWrapper>
	)
}

export default SimulationView
