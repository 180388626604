/* eslint-disable react-perf/jsx-no-new-object-as-prop, react-perf/jsx-no-new-array-as-prop, react-perf/jsx-no-new-function-as-prop */

import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

const Bar = ({
	data,
	keys,
	indexBy,
	yLegend,
	colors,
	hasAxisLeft,
	marginTopPx = 0,
	marginRightPx = 0,
	marginBottomPx = 0,
	marginLefPx = 0,
	animate = false,
}: {
	data: { [key: string]: string | number }[]
	keys: string[]
	indexBy: string
	yLegend: string
	colors?: string[]
	hasAxisLeft?: boolean
	marginPx?: number
	marginTopPx?: number
	marginRightPx?: number
	marginBottomPx?: number
	marginLefPx?: number
	animate?: boolean
}) => {
	return (
		<ResponsiveBar
			animate={animate}
			data={data}
			keys={keys}
			indexBy={indexBy}
			margin={{ top: marginTopPx, right: marginRightPx, bottom: marginBottomPx, left: marginLefPx }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={colors || { scheme: 'set2' }}
			layout="vertical"
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: '#38bcb2',
					size: 4,
					padding: 1,
					stagger: true,
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: '#eed312',
					rotation: -45,
					lineWidth: 6,
					spacing: 10,
				},
			]}
			fill={[
				{
					match: {
						id: 'fries',
					},
					id: 'dots',
				},
				{
					match: {
						id: 'sandwich',
					},
					id: 'lines',
				},
			]}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 90,
				legendPosition: 'middle',
				legendOffset: 32,
			}}
			axisLeft={
				hasAxisLeft
					? {
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: yLegend,
							legendPosition: 'middle',
							legendOffset: -40,
					  }
					: null
			}
			labelSkipWidth={1}
			labelSkipHeight={12}
			labelTextColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			role="application"
			ariaLabel="Nivo bar chart demo"
			barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
		/>
	)
}

export default Bar
