import { gql } from '@apollo/client'

export const UserInfoFragment = gql`
	fragment UserInfoFragment on User {
		id
		email
		firstName
		lastName
		isTestUser
		emailConfirmed
	}
`

export const RestaurantInfoFragment = gql`
	fragment RestaurantInfoFragment on Restaurant {
		id
		title
		lat
		lon
		votes
		score
		partialMinOrderValue
		included
	}
`

export const CommunityInfoFragment = gql`
	fragment CommunityInfoFragment on Community {
		id
		title
		lat
		lon
		restaurants {
			...RestaurantInfoFragment
		}
		inhabitants
		squareKilometers
		increasePercent
	}
	${RestaurantInfoFragment}
`

export default null
